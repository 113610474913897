'use client';

import { initializeApp } from 'firebase/app';
import { createContext, useContext, ReactNode } from 'react';
// config
import { FIREBASE_API } from 'src/config-global';
// ----------------------------------------------------------------------

export const firebaseApp = initializeApp(FIREBASE_API);

// ----------------------------------------------------------------------

const FirebaseContext = createContext<any | null>(null);

export const useFirebase = () => useContext(FirebaseContext);

interface FirebaseProviderProps {
  children: ReactNode;
}

export const FirebaseProvider = ({ children }: FirebaseProviderProps) => (
  <FirebaseContext.Provider value={firebaseApp}>{children}</FirebaseContext.Provider>
);
