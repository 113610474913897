import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { alpha } from '@mui/material/styles';
import { m, AnimatePresence } from 'framer-motion';
import { fData } from 'src/utils/format-number';

import { varFade } from '../animate';
import FileThumbnail, { fileData } from '../file-thumbnail';
import Iconify from '../iconify';
import Preview from './preview';
import { UploadProps } from './types';

export default function MultiFilePreview({ thumbnail, files, onRemove, sx }: UploadProps) {
  return (
    <AnimatePresence initial={false}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        display="grid"
        gridTemplateColumns={{
          xs: `repeat(${files && files?.length > 1 ? '2' : '1'}, 1fr)`,
          md: 'repeat(4, 1fr)',
        }}
      >
        {files?.map((file, index) => {
          const { key, name = '', size = 0 } = fileData(file);
          const isNotFormatFile = typeof file === 'string';

          if (thumbnail && file) {
            return (
              <Preview key={index} index={index} files={files} file={file} onRemove={onRemove} />
            );
          }

          return (
            <Stack
              key={key}
              component={m.div}
              {...varFade().inUp}
              spacing={2}
              direction="row"
              alignItems="center"
              sx={{
                my: 1,
                py: 1,
                px: 1.5,
                borderRadius: 1,
                border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.16)}`,
                ...sx,
              }}
            >
              <FileThumbnail file={file} />

              <ListItemText
                primary={isNotFormatFile ? file : name}
                secondary={isNotFormatFile ? '' : fData(size)}
                secondaryTypographyProps={{
                  component: 'span',
                  typography: 'caption',
                }}
              />

              {onRemove && (
                <IconButton size="small" onClick={() => onRemove(file)}>
                  <Iconify icon="mingcute:close-line" width={16} />
                </IconButton>
              )}
            </Stack>
          );
        })}
      </Stack>
    </AnimatePresence>
  );
}
